import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersCoursesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminHeader, AdminTable} from "@plumeuk/shapeshift-common/admin";
import {HeadCell} from "@plumeuk/shapeshift-common/admin/table/EnhancedTableHead";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells: HeadCell<IAdminUsersCoursesResponseEntry>[] = [
	{
		id: "title",
		label: "Course"
	},
	{
		id: "tags",
		label: "Tags"
	},
	{
		id: "languages",
		label: "Languages"
	},
	{
		id: "createdAt",
		label: "Created"
	},
	{
		id: "publishedAt",
		label: "Published"
	}
];

export const AdminCoursesList: FC = () => {
	const {classes} = useStyles();
	const [selected, setSelected] = useState<IAdminUsersCoursesResponseEntry[]>([]);
	const navigate = useNavigate();
	// const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminCoursesList}>
			<AdminHeader
				title="Courses"
				subtitle="Manage courses, enrol them into courses and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"course", IAdminUsersCoursesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/course"
				headCells={headCells}
				onEditAction={e => navigate(e.id.toString())}
				selected={selected}
				enableDelete
				type="course"
				setSelected={setSelected}
				rowRender={(course) => (
					<>
						<TableCell component="th" scope="row">
							{course.title}
						</TableCell>
						<TableCell>{course.tags.join(", ")}</TableCell>
						<TableCell>{course?.languages?.map(e => locales?.data?.find(x => x.code === e)?.name)?.join(", ")}</TableCell>
						<TableCell>{new Date(course.createdAt).toDateString()}</TableCell>
						<TableCell>{course.publishedAt ? new Date(course.publishedAt).toDateString() : ""}</TableCell>
					</>
				)}
			/>
		</Box>
	);
}
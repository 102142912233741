import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {AdminQuizPage} from "./AdminQuizPage";
import {AdminQuizsList} from "./AdminQuizzesList";

const useStyles = makeStyles()((theme) => ({
	adminQuizzesPage: {

	}
}));

export const AdminQuizzesPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminQuizzesPage}>
			<Routes>
				<Route index element={<AdminQuizsList />}/>
				<Route path="create" element={<AdminQuizPage />} />
				<Route path=":quizId" element={<AdminQuizPage />} />
			</Routes>
		</Box>
	);
}
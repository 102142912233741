import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersQuizzesResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {HeadCell} from "@plumeuk/shapeshift-common/admin/table/EnhancedTableHead";
import {AdminHeader, AdminTable} from "@plumeuk/shapeshift-common/admin";

const useStyles = makeStyles()((_theme) => ({
	adminQuizsList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells: HeadCell<IAdminUsersQuizzesResponseEntry>[] = [
	{
		id: "title",
		label: "Quiz"
	},
	{
		id: "languages",
		label: "Languages"
	},
	{
		id: "createdAt",
		label: "Created"
	},
	{
		id: "publishedAt",
		label: "Published"
	}
];

export const AdminQuizsList: FC = () => {
	const {classes} = useStyles();
	const [selected, setSelected] = useState<IAdminUsersQuizzesResponseEntry[]>([]);
	const navigate = useNavigate();
	// const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminQuizsList}>
			<AdminHeader
				title="Quizzes"
				subtitle="Manage quizzes"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"quiz", IAdminUsersQuizzesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/quiz"
				headCells={headCells}
				enableDelete
				type="quiz"
				onEditAction={e => navigate(e.id.toString())}
				selected={selected}
				setSelected={setSelected}
				rowRender={(quiz, _reload) => (
					<>
						<TableCell component="th" scope="row">
							{quiz.title}
						</TableCell>
						<TableCell>{quiz?.languages?.map(e => locales?.data?.find(x => x.code === e)?.name)?.join(", ")}</TableCell>
						<TableCell>{new Date(quiz.createdAt).toDateString()}</TableCell>
						<TableCell>{quiz.publishedAt ? new Date(quiz.publishedAt).toDateString() : ""}</TableCell>
					</>
				)}
			/>
		</Box>
	);
}
import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IAdminUsersResponseEntry, ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminHeader, AdminTable} from "@plumeuk/shapeshift-common/admin";
import {HeadCell} from "@plumeuk/shapeshift-common/admin/table/EnhancedTableHead";

const useStyles = makeStyles()((_theme) => ({
	adminUsersList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells: HeadCell<IAdminUsersResponseEntry>[] = [
	{
		id: "email",
		label: "Email"
	},
	{
		id: "firstname",
		label: "First"
	},
	{
		id: "lastname",
		label: "Last"
	},
	{
		id: "createdAt",
		label: "Created"
	}
];

export const AdminUsersList: FC = () => {
	const {classes} = useStyles();
	const [selected, setSelected] = useState<IAdminUsersResponseEntry[]>([]);
	const navigate = useNavigate();
	// const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");
	const locales:{data: ILocalizationEntity[]} = {data: []}; //> pending conversation on locale support

	return (
		<Box className={classes.adminUsersList}>
			<AdminHeader
				title="Users"
				subtitle="Manage users, enrol them into users and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"users", IAdminUsersResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/users"
				headCells={headCells}
				onEditAction={e => navigate(e.id.toString())}
				selected={selected}
				enableDelete
				type="users"
				setSelected={setSelected}
				rowRender={(user) => (
					<>
						<TableCell component="th" scope="row">{user.email}</TableCell>
						<TableCell>{user.firstname}</TableCell>
						<TableCell>{user.lastname}</TableCell>
						<TableCell>{new Date(user.createdAt).toDateString()}</TableCell>
					</>
				)}
			/>
		</Box>
	);
}
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IUserEntity, IUserEntityPayload} from "@plumeuk/shapeshift-types";
import {useNavigate, useParams} from "react-router-dom";
import {AdminCourseCurriculum, AdminEntityPageContainer, AdminFieldContainer, AdminInputText, AdminUsersCourses} from "@plumeuk/shapeshift-common/admin"
import {APIState} from "@plumeuk/shapeshift-identity";

interface IProps {
}

const useStyles = makeStyles()((theme) => ({
	adminUserPage: {
	}
}));

const template: IUserEntityPayload = {
	email: "", firstname: "", lastname: ""
}

export const AdminUserPage: FC<IProps> = () => {
	const {id: courseId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = courseId ? parseInt(courseId) : undefined;

	const IUserEntityToIUserEntityPayload = (e: IUserEntity): IUserEntityPayload => {
		const {email, firstname, lastname, id, createdAt, updatedAt} = e
		const payload = {
			email, firstname, lastname, id, createdAt, updatedAt
		}
		return payload;
	}

	const handleOnSaveResponse = (e: APIState<IUserEntity>): void => {

	}

	const handleOnGetResponse = (entityApiResponse: APIState<IUserEntity>): void => {
		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<IUserEntity>): void => {
		if(deleteApiResponse.statusCode === 200){
			navigate("../")
		}
	}

	return (
		<Box className={classes.adminUserPage}>
			<AdminEntityPageContainer<IUserEntity, "users">
				entityId={entityId}
				entityToPayload={IUserEntityToIUserEntityPayload}
				template={template}
				type="users"
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
			>
				{({formData, setFormData, entity, locale, files, setFiles}) => <>
					<AdminInputText label="Email" onChange={e => setFormData(prev => ({...prev, email: e.target.value}))} value={formData.email}/>
					<AdminInputText label="First Name" onChange={e => setFormData(prev => ({...prev, firstname: e.target.value}))} value={formData.firstname}/>
					<AdminInputText label="Last Name" onChange={e => setFormData(prev => ({...prev, lastname: e.target.value}))} value={formData.lastname}/>
					{entity?.id && <AdminFieldContainer required label="Enrollment"><AdminUsersCourses initialData={entity} value={formData.courses} onChange={e => setFormData(prev => ({...prev, courses: e}))}/></AdminFieldContainer>}

				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IQuizEntity, IQuizEntityPayload} from "@plumeuk/shapeshift-types";
import {useNavigate, useParams} from "react-router-dom";
import {AdminEntityPageContainer, AdminInputSwitch, AdminInputText} from "@plumeuk/shapeshift-common/admin";
import {APIState} from "@plumeuk/shapeshift-identity";
import {AdminFieldContainer} from "@plumeuk/shapeshift-common/admin";
import Editor from "../Editor/Editor";

interface IProps {
}

const useStyles = makeStyles()((theme) => ({
	adminQuizPage: {

	}
}));

const template: IQuizEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminQuizPage: FC<IProps> = () => {
	const {quizId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = quizId ? parseInt(quizId) : undefined;

	const IQuizEntityToIQuizEntityPayload = (e: IQuizEntity): IQuizEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, subtitle} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, subtitle, videoData
		}
		return payload;
	}

	const handleOnSaveResponse = (e: APIState<IQuizEntity>): void => {
		if(!e.data)
			return;
		navigate("../" + e.data.id.toString())
	}

	const handleOnGetResponse = (entityApiResponse: APIState<IQuizEntity>): void => {
		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<IQuizEntity>): void => {
		if(deleteApiResponse.statusCode === 200){
			navigate("../")
		}
	}

	return (
		<Box className={classes.adminQuizPage}>
			<AdminEntityPageContainer<IQuizEntity, "quiz">
				entityId={quizId ? parseInt(quizId) : undefined}
				entityToPayload={IQuizEntityToIQuizEntityPayload}
				template={template}
				type="quiz"
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
			>
				{({formData, setFormData, entity, locale}) => <>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Quiz title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Quiz subtitle"}/>
					<AdminFieldContainer label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
					{locale === "en" && <AdminInputText required type="number" onChange={e => setFormData(prev => ({...prev, scoreRequired: e.target.value as any as number}))} value={formData.scoreRequired} label={"Quiz Pass Threshold"}/>}
					{locale === "en" && <AdminInputSwitch onChange={e => setFormData(prev => ({...prev, randomiseOrder: e.target.checked}))} checked={formData.randomiseOrder} label={"Randomise Question Order"}/>}
					{/* {locale === "en" && <AdminFieldContainer required label="Questions"><AdminQuizBuilder onChange={e => setFormData(prev => ({...prev, composition: e}))} initialData={entity} /></AdminFieldContainer>} */}
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}
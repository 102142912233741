import {Box, Button, Typography} from "@mui/material";
import {IconContext, useApi} from "@plumeuk/shapeshift-identity";
import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2";
import {ICourseLibraryBannerResponse} from "../../types/courseLibraryBanner";
import {Link} from "react-router-dom";

interface IStyleProps {
	backgroundUrl?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {backgroundUrl}) => ({
	courseLibraryBanner: {
	},
	courseLibraryBannerInner: {
		borderRadius: theme.borderRadius.medium,
		padding: theme.spacing(5),
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		backgroundColor: theme.palette.background.paper,
		background: backgroundUrl && `url('${backgroundUrl}') no-repeat`,
		backgroundSize: "cover",
		boxSizing: "border-box",
		minHeight: theme.constants.bannerHeight || "270px"
	},
	content: {
		color: theme.palette.text.secondary,
		maxWidth: "900px",
		marginBottom: theme.spacing(4)
	},
	courseLibraryBannerTitle: {
		maxWidth: "720px",
		marginBottom: theme.spacing(3)
	},
	bannerButton: {
		background: theme.palette.primary.dark,
		marginBottom: theme.spacing(1)
	}
}));

export const CourseLibraryBanner: FC = () => {
	const [{data: apiData}] = useApi<ICourseLibraryBannerResponse>("/api/course-library-banner?populate=backgroundImage");
	const bannerData = apiData?.data.attributes;

	const hasBannerContent =
		bannerData?.title ||
		bannerData?.content ||
		bannerData?.link ||
		bannerData?.backgroundImage?.data;

	const {classes} = useStyles({
		backgroundUrl: bannerData?.backgroundImage?.data?.attributes?.url
	});

	const {icon} = useContext(IconContext);

	return (
		<Box className={classes.courseLibraryBanner}>
			{hasBannerContent &&
				<Box className={classes.courseLibraryBannerInner}>
					{bannerData?.title &&
						<Typography className={classes.courseLibraryBannerTitle} variant="h2">
							{bannerData?.title}
						</Typography>
					}
					{bannerData.content &&
						<WYSIWYG className={classes.content}>
							{bannerData.content}
						</WYSIWYG>
					}
					{bannerData?.link &&
						<Button
							component={Link}
							to={bannerData.link}
							className={classes.bannerButton}
							endIcon={icon("arrowForwardIcon")}
							size="large"
						>
							Read more
						</Button>}
				</Box>
			}
		</Box>
	);
}